import { useEffect } from 'react';

import { usePersistReducer, initialState } from '../Store';
import { useRouter } from '../../../hooks/useRouter';
import { formatUrl } from '../../../utils/url';
import { jobContext } from '../Context';
import { validateInitialValues } from '../../../utils/form';
import { jobFormProperties } from '../../../types/job';
import { Urls } from '../../urls';

import { useArchiveFile } from '../../../graph/mutations/archiveFile';
import { useCreateContact } from '../../../graph/mutations/createContact';
import { useGetJob } from '../../../graph/queries/getJob';
import { useUpdateJob } from '../../../graph/mutations/updateJob';
import { useArchiveContact } from '../../../graph/mutations/archiveContact';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import { Sidebar, SidebarLayout, SidebarContent } from '../../../components/atoms/Layout/Layout';
import { VerticalNav, VerticalNavItem, VerticalNavStatus } from '../../../components/molecules/VerticalNav';
import {
  SuitcaseIcon,
  MoneyBillIcon,
  OfficeBuildingIcon,
  PeopleFullIcon,
  StarIcon,
} from '../../../components/atoms/Icons';
import { Agency } from '../Common/Agency';
import { Company } from '../Common/Company';
import { Job } from '../Common/Job';
import { Rate } from '../Common/Rate';
import { Confirm } from '../Common/Confirm';

const LOCAL_STORAGE_KEY = 'job';

export const Update = () => {
  const {
    navigate,
    location: { pathname },
    query: { userUuid = '', boardUuid = '', jobUuid = '' },
  } = useRouter();
  const [state, dispatch] = usePersistReducer(LOCAL_STORAGE_KEY, initialState);
  const [updateJob, updateJobState] = useUpdateJob();
  const [archiveFile, archiveFileState] = useArchiveFile();
  const [createContact, createContactState] = useCreateContact();
  const [archiveContact, archiveContactState] = useArchiveContact();
  const { data: { job } = {} } = useGetJob({
    variables: { userUuid, boardUuid, jobUuid, includeDeleted: true },
    fetchPolicy: 'network-only',
  });
  const [isVerticalNavOpen, setIsVerticalNavOpen] = useLocalStorage('isJobUpdateOpen', true);

  useEffect(() => {
    if (job) {
      if (job.updatedAt) {
        dispatch({
          type: 'CHANGE_MAX_VALID_STEP',
          payload: 5,
        });
      }
      dispatch({
        type: 'INITIALIZE_FORM_VALUES',
        payload: validateInitialValues(job, jobFormProperties),
      });

      dispatch({
        type: 'INITIALIZE_SAVED_FILES',
        payload: job.files,
      });

      dispatch({
        type: 'INITIALIZE_SAVED_CONTACTS',
        payload: job.contacts,
      });
    }
  }, [job]);

  useEffect(() => {
    const step = +pathname.substring(pathname.length - 1) || 1;
    dispatch({
      type: 'CHANGE_STEP',
      payload: step,
    });
  }, [pathname]);

  const context = {
    title: 'Update job',
    state,
    dispatch,
    updateJob,
    updateJobState,
    archiveFile,
    archiveFileState,
    createContact,
    createContactState,
    archiveContact,
    archiveContactState,
  };

  if (!userUuid || !boardUuid) {
    return null;
  }

  return (
    <SidebarLayout>
      <jobContext.Provider value={context}>
        <Sidebar>
          <VerticalNav isOpen={isVerticalNavOpen} setIsOpen={setIsVerticalNavOpen}>
            <VerticalNavItem
              active={state.currentstep === 1}
              enabled={state.maxValidStep > 0}
              isOpen={isVerticalNavOpen}
              hasErrors={state.errorsTabs.includes('agency')}
              onClick={() => {
                if (state.maxValidStep > 0) {
                  navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep1}`, { userUuid, boardUuid, jobUuid }));
                }
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === 1}
                  enabled={state.maxValidStep > 0}
                  icon={<PeopleFullIcon />}
                />
              }
            >
              Agency
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 2}
              enabled={state.maxValidStep > 1}
              isOpen={isVerticalNavOpen}
              hasErrors={state.errorsTabs.includes('company')}
              onClick={() => {
                if (state.maxValidStep > 1) {
                  navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep2}`, { userUuid, boardUuid, jobUuid }));
                }
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === 2}
                  enabled={state.maxValidStep > 1}
                  icon={<OfficeBuildingIcon />}
                />
              }
            >
              Company
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 3}
              enabled={state.maxValidStep > 2}
              isOpen={isVerticalNavOpen}
              hasErrors={state.errorsTabs.includes('job')}
              onClick={() => {
                if (state.maxValidStep > 2) {
                  navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep3}`, { userUuid, boardUuid, jobUuid }));
                }
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === 3}
                  enabled={state.maxValidStep > 2}
                  icon={<SuitcaseIcon />}
                />
              }
            >
              Job
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 4}
              enabled={state.maxValidStep > 3}
              isOpen={isVerticalNavOpen}
              hasErrors={state.errorsTabs.includes('rate')}
              onClick={() => {
                if (state.maxValidStep > 3) {
                  navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep4}`, { userUuid, boardUuid, jobUuid }));
                }
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === 4}
                  enabled={state.maxValidStep > 3}
                  icon={<MoneyBillIcon />}
                />
              }
            >
              Rate
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 5}
              enabled={state.maxValidStep > 4}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                if (state.maxValidStep > 4) {
                  navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep5}`, { userUuid, boardUuid, jobUuid }));
                }
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === 5}
                  enabled={state.maxValidStep > 4}
                  icon={<StarIcon />}
                />
              }
            >
              Confirm
            </VerticalNavItem>
          </VerticalNav>
        </Sidebar>
        <SidebarContent>
          {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
          {state.currentstep === 1 && <Agency />}
          {state.currentstep === 2 && <Company />}
          {state.currentstep === 3 && <Job />}
          {state.currentstep === 4 && <Rate />}
          {state.currentstep === 5 && <Confirm />}
        </SidebarContent>
      </jobContext.Provider>
    </SidebarLayout>
  );
};
