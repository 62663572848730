export const jobFormProperties = {
  isThroughAgency: 'boolean',
  agencyName: 'string',
  agentName: 'string',
  agentEmail: 'string',
  agentPhone: 'string',
  referralFee: 'string',

  company: 'string',
  companyInfo: 'string',
  companyWebsite: 'string',
  companyLinkedIn: 'string',
  companyLocation: 'string',
  companyLocationCity: 'string',
  companyLocationCountry: 'string',
  companyLocationPostCode: 'string',
  companyLocationStreet: 'string',
  companyLocationUrl: 'string',
  companyLocationLatitude: 'number',
  companyLocationLongitude: 'number',

  jobTitle: 'string',
  jobSeniorityLevel: 'enum',
  jobDescription: 'string',
  jobRequirement: 'string',
  jobUrl: 'string',
  employmentType: 'enum',
  remoteOption: 'enum',
  status: 'enum',
  duration: 'string',
  rate: 'string',
  ir35: 'boolean',
};

export const jobProperties = {
  ...jobFormProperties,
  id: 'key',
  relation: 'key',
  uuid: 'string',
  createdAt: 'datetime',
  updatedAt: 'datetime',
  isDeleted: 'boolean',
};
